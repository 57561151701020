import axios from "axios";
import { config } from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

class SnmpDevicesService {
  getAllSnmpDevices(url) {
    return axios.get(API_URL + url, {
      headers: authHeader(),
    });
  }
  getAllAvailablePorts(url) {
    return axios.get(API_URL + url, {
      headers: authHeader(),
    });
  }
  getSnmpDevice(url) {
    return axios.get(API_URL + url, {
      headers: authHeader(),
    });
  }
  addSnmpDevice(url, setting) {
    return axios.post(API_URL + url, setting, {
      headers: authHeader(),
    });
  }
  updateSnmpDevice(url, setting) {
    return axios.put(API_URL + url, setting, {
      headers: authHeader(),
    });
  }
  deleteSnmpDevice(url) {
    return axios.delete(API_URL + url, {
      headers: authHeader(),
    });
  }
  getSnmpDevicesHistory(url) {
    return axios.get(API_URL + url, {
      headers: authHeader(),
    });
  }
}
export default new SnmpDevicesService();