<template>
  <Spinner v-show="!loaded"></Spinner>
  <div>
    <b-modal
      v-model="enableDelete"
      size="sm"
      title="Delete Snmp Device"
      hide-backdrop
      ok-title="Delete"
      @ok="deleteSnmpDevice(singleDeleteData.id)">
      Are you sure you want to delete this Snmp Device?
    </b-modal>
  </div>
  <b-col lg="3">
      <b-button variant="primary" class="mr-2" size="sm" :to="'/snmp-devices/add/'">
        Add Snmp Device
          <font-awesome-icon icon="pencil" />
      </b-button>
      </b-col>
  <b-row class="p-3" v-show="setting.length > 0" >
    <div class="col-8 w-100 table-responsive-lg">
      <table class="table table-dark">
        <thead class="thead-dark">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">IP Address</th>
            <th scope="col">Community</th>
            <th scope="col">Poll Freqency</th>
            <th scope="col">Monitored Ports</th>
            <th scope="col">Created</th>
            <th scope="col">Updated</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="element in setting" :key="element">
            <td class="text-white" scope="row">{{ element.id }}</td>
            <td class="text-white">
              {{ element.ip }}
            </td>
            <td class="text-white">
              {{ element.community }}
            </td>
            <td class="text-white">
              {{ element.pollFrequency }}
            </td>
            <td class="text-white">
              {{ element.monitoredPorts?.length }}
            </td>
            <td class="text-white" v-if="element">
              {{ getDateString(new Date(element.created_at).toISOString()) }}
            </td>
            <td class="text-white" v-if="element">
              {{ getDateString(new Date(element.updated_at).toISOString()) }}
            </td>
            <td class="text-white">
              <b-button
                variant="primary"
                class="mr-3"
                size="sm"
                :to="'/snmp-devices/edit/' + element.id">
                <font-awesome-icon icon="pencil" />
              </b-button>
              &nbsp;
              <b-button
                variant="danger"
                class="mr-2"
                size="sm"
                @click="enableDeletion(element.id)">
                <font-awesome-icon icon="trash" />
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-row>

  <div v-show="setting.length == 0 && loaded">
    <h1 class="text-white text-center mt-3">No SNMP Devices</h1>
  </div>
</template>
<script>
  import SnmpDevicesService from "../../services/snmp-devices.service";
  import "vue3-toastify/dist/index.css";
  import { notify } from "../../helpers/index";
  import { DateTime } from "luxon";
  import Spinner from "../../components/spinner/spinner.vue";

  export default {
    name: "SnmpDevice",
    components: {
      Spinner,
    },
    data: function () {
      return {
        setting: [],
        fields: [
          { key: "id", label: "ID" },
          { key: "ip", label: "IP Address" },
          { key: "community", label: "Community" },
          { key: "pollFrequency", label: "Poll Frequency" },
          { key: "monitoredPorts", label: "Monitored Ports" },
          { key: "createdAt", label: "Created" },
          { key: "updatedAt", label: "Updated" },
          { key: "actions", label: "Actions" },
        ],
        enableDelete: false,
        singleDeleteData: {},
        loaded: false,
      };
    },
    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      },
    },
    mounted() {
      this.getAllSnmpDevices();
    },
    methods: {
      enableDeletion(id) {
        (this.enableDelete = true),
          (this.singleDeleteData = {
            id: id,
          });
      },
      logOut() {
        this.$store.dispatch("auth/logout");
        this.$router.push("/login");
      },
      async getAllSnmpDevices() {
        const url = "snmp-devices";
        
        try {
          const reply = await SnmpDevicesService.getAllSnmpDevices(url);
          if (reply.status == 200 && reply.data) {
            this.setting = reply.data;
            this.loaded = true;
          }
        }catch {
          notify("Session expired", "error");
          this.logOut();
        }
      },
      async deleteSnmpDevice(id) {
        const url = `snmp-devices/${id}`;
        console.log(url)
        try {
          const response = await SnmpDevicesService.deleteSnmpDevice(url);
          if (response.status == 200 && response.data) {
            notify("Snmp Device Deleted Successfully");
            this.getAllSnmpDevices();
          }
          else {
            notify("Error deleting Snmp Device", "error");
          }
        }catch {
          notify("Session expired", "error");
          this.logOut();
        }
        
      },
      getDateString(date) {
        return DateTime.fromISO(date).toFormat("yyyy-M-d");
      },
      capitalize(string) {
        string.charAt(0).toUpperCase() + string.slice(1);
      },
    },
  };
</script>
<style scoped>
  .bg-secondary {
    background-color: #f7fafc !important;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    scale: 0.7;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
</style>
